import reducers from './reducers';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

const enhancers = [
    applyMiddleware(
        thunkMiddleware,
        //navigationReduxMiddleware,
        createLogger({
            collapsed: true,
            // eslint-disable-next-line no-undef
            predicate: () => (window as any).__DEV__,
        }),
    ),
];

/* eslint-disable no-undef */
const composeEnhancers =
    ((window as any).__DEV__ &&
        typeof window !== 'undefined' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
/* eslint-enable no-undef */

const enhancer = composeEnhancers(...enhancers);

const store = createStore(reducers, enhancer);

export default store;
