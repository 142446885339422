import { LOGIN, LOGOUT, USER_UPDATED } from '../actionTypes';

const initialAuthState = { isLoggedIn: false, user: null };

const auth = (state = initialAuthState, action: any) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isLoggedIn: true, user: action.payload };
        case USER_UPDATED:
            return { ...state, user: action.payload };
        case LOGOUT:
            return { ...state, isLoggedIn: false, user: null };
        default:
            return state;
    }
}

export default auth;
