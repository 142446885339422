import React from 'react'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import LoadingOverlay from 'react-loading-overlay'

const PageLoading = () => (<LoadingOverlay active={true} text='Chargement...' spinner />);

const AsyncLogin = Loadable({
  loader: () => import('../pages/Login'),
  loading: PageLoading,
})

const AsyncLayout = Loadable({
  loader: () => import('../layouts/Layout'),
  loading: PageLoading,
})


const GuestRouter = (props:any) => (
    <Switch>        
      <PublicRoute path='/login'><AsyncLogin /></PublicRoute>
      <PrivateRoute><AsyncLayout /></PrivateRoute>
    </Switch>
)

export default GuestRouter;