import { useState, useContext, createContext } from "react";
import { toast } from "react-toastify";
import { AuthToken, User } from "../models";
import APIKit, { API, setAccessToken } from "../utils/APIKit";
import { LC_AUTH_TOKEN } from "../utils/AppConst";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */

type ContextType = {
  user: User 
  login: Function,
  logout: Function,
  setUser: Function
};

const authContext = createContext<ContextType>({
  user: null,
  login: () => null,
  logout: () => null,
  setUser: (_: User) => null
});

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
}

const useProvideAuth = () => {

  const fetchUserAndToken = () => {
    const authStr = localStorage.getItem(LC_AUTH_TOKEN);
    if (authStr) {
      const authToken = JSON.parse(authStr) as AuthToken;
        if ((new Date(authToken.token.expires_at)).getTime() > (new Date()).getTime()) {
          setAccessToken(authToken.token.access_token);
          return authToken.user;
        }
    }

    return null;
  }

  const [user, setUser] = useState<User>(fetchUserAndToken());

  const login = async (email: string, password: string, remember: boolean = false): Promise<boolean> => {
    const data = {email, password, 'remember_me': remember};
    return APIKit.post<AuthToken>(API.AUTH.login, data)
      .then(({data}) => data)
      .then(authToken => {
        localStorage.setItem(LC_AUTH_TOKEN, JSON.stringify(authToken));
        setUser(authToken.user);
        setAccessToken(authToken.token.access_token);
        toast(`Bienvenue M. ${authToken.user.name}, nous sommes content de vous revoir !`, {
          hideProgressBar: true,
          type: 'success'
        });
        return true;
      })
      .catch(_ => false);
  }

  const logout = (): Promise<any> => {
    setUser(null);
    return APIKit.post(API.AUTH.logout).finally(() => {
      toast("Vous vous êtes déconnecté avec succès !", {
        hideProgressBar: true,
        type: 'info'
      });
      localStorage.removeItem(LC_AUTH_TOKEN);
    });
  };

  return {
    setUser,
    user,
    login,
    logout
  };
}
