import React from 'react';
import { Provider } from 'react-redux';
import { ProvideAuth } from './hooks/use-auth';
import store from './redux/store';
import GuestRouter from './routes/GuestRouter';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ProvideAuth>
      <Provider store={store}>
        <BrowserRouter forceRefresh={false}>
          <GuestRouter />
          <ToastContainer limit={1} />
        </BrowserRouter>
      </Provider>
    </ProvideAuth>
  );
}

export default App;
