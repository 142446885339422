import axios from "axios";
import { toast } from "react-toastify";
import { LC_AUTH_TOKEN } from "./AppConst";

const __DEV__ = "development" === process.env.NODE_ENV;

// The server url
export const SERVER_URL = __DEV__
  ? "http://localhost:8000"
  : "https://echurchv1-api.visibilitycam.com";
//export const SERVER_URL = 'https://echurchv1-api.visibilitycam.com';

// Create axios client, pre-configured with baseURL
const APIKit = axios.create({
  baseURL: `${SERVER_URL}/admin-api`,
  timeout: 100000,
});

APIKit.defaults.headers.common["XADMIN"] = `Admin`;

// Set JSON Web Token in Client to be included in all calls
export const setAccessToken = (token) =>
  (APIKit.defaults.headers.common["Authorization"] = `Bearer ${token}`);

APIKit.interceptors.request.use((req) => {
  __DEV__ && console.log("REQUEST", req);
  return req;
});

APIKit.interceptors.response.use(
  (res) => {
    __DEV__ && console.log("RESPONSE", res);
    return res;
  },
  (error) => {
    __DEV__ && console.log("ERROR", error);
    __DEV__ && console.log("RESPONSE-ERROR", error.response);
    if (error.response) {
      if (error.response.status === 401) {
        // log out user if we got a 401
        localStorage.removeItem(LC_AUTH_TOKEN);
      }

      if (error.response.status === 500) {
        // log out user if we got a 500
        toast.error("Une erreur ces produites !", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
        throw error;
      }

      if (error.response.status == 400) {
        console.log(error.response.data.message)
        // log out user if we got a 400
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
        throw error;
      }
    } else {
      toast.error("Vous êtes hors ligne !", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
      throw error;
    }
  }
);

// All backend api url
export const API = {
  AUTH: {
    login: "auth/login",
    logout: "auth/logout",
  },
  uploadImage: "upload-image",
  graphql: "graphql",
  Finance: "/finance",
  PARISH: "/update_menu/",
  UpdateVersion: "/update_version",
  DonationFinance: "/donation_finance",
  StorageParish: "/addParish",
  GetParish: "/getAllParish",
  updateParish: "/updateParish/",
  userCharts: "/usercharts1",
  ACCUEIL: {
    donation: "pattern_donations",
    biblicVerset: "bible_verses",
    dayImage: "picture_days",
  },
  Liturgical: {
    typePriere: "type_prieres",
    typeChant: "type_chants",
    chant: "chant",
    priere: "priere",
  },
  AUJOURDHUI: {
    homelie: "homelies",
    saintDay: "holy_days",
    textDay: "text_days",
    wordExercice: "word_exercices",
  },
} as const;

export default APIKit;
